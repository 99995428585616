import { Watch } from "react-loader-spinner"


const Loader = () =>{

return (
    <div className="loader">
          <Watch
            height="80"
            width="80"
            radius="48"
            color="#dca953"
            ariaLabel="watch-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
)

}

export default Loader