
import "../../css/dock.css"
import userIcon from "../../imgs/user.png"
import projects from "../../imgs/interface/project.png"
import inventory from "../../imgs/interface/inventory.png"
import vehicles from "../../imgs/interface/car-icon.png"
import locations from "../../imgs/HouseRentals.png"
import furniture from "../../imgs/interface/sofa.png"
import actors from "../../imgs/interface/actors.png"
import business from "../../imgs/interface/compagnie.png"
import people from "../../imgs/interface/clients.png"

import lotteryIcon from "../../imgs/lottery-small.png"
import settingsIcon from "../../imgs/settings.png"
import listingsIcon from "../../imgs/EpicMarker.png"
import desktopIcon from "../../imgs/interface/desktop.png"

import art from "../../imgs/interface/art.png"
import storesIcon from "../../imgs/marketPlace.png"
import notificationsIcon from "../../imgs/Notification.png"
import UserMenu from "./userMenu"
import { useState } from "react"

import { motion } from "framer-motion"
import { useGlobalContext } from "../../hooks/useGlobalContext"
import { Link, useLocation } from "react-router-dom"


const Dock = ({ setShowSettings, setShowWallets }) => {

    const [showMouseOver, setShowMouseOver] = useState({
        userMenu: false,
        projects: false,
        inventory: false,
        rentals: false,
        stores: false,
        lottery: false,
        settings: false,
        notifications: false,
        furniture: false,
        actors: false,
        business: false,
        people: false,
        desktop: false,

    })

    const { user } = useGlobalContext()

    const location = useLocation()

    const setAllFalse = () => {
        setShowMouseOver({
            userMenu: false,
            projects: false,
            inventory: false,
            rentals: false,
            stores: false,
            lottery: false,
            settings: false,
            notifications: false,
            furniture: false,
            actors: false,
            business: false,
            people: false,
            desktop: false,
        })

    }


    return (
        <>
            <div className="dock">

                <div className="dock-btn"
                    onMouseEnter={() => {
                        setAllFalse();
                        setShowMouseOver((prev) => ({
                            ...prev,
                            userMenu: true,
                        }));

                    }}><div className="imgIcon">
                        <img src={user && user.avatar} style={{ borderRadius: "25px" }} alt="" />
                    </div>
                </div>
                <div className="separator">

                </div>
                <div className="appMenu d-flex">

                    <div className="dock-btn"
                        onMouseEnter={() => {
                            setAllFalse();
                            setShowMouseOver((prev) => ({
                                ...prev,
                                projects: true,
                            }));
                        }}
                        onMouseLeave={() => {

                            setShowMouseOver({
                                ...showMouseOver,
                                projects: false
                            })
                        }}>
                        {showMouseOver.projects &&
                            <div className="btn-title">
                                Projects
                            </div>
                        }
                        <div className="imgIcon">
                            <img src={projects} alt="" />
                        </div>

                    </div>
                    <div className="dock-btn" style={{ marginLeft: "3px" }}
                        onMouseEnter={() => {
                            setAllFalse();
                            setShowMouseOver((prev) => ({
                                ...prev,
                                inventory: true,
                            }));
                        }}
                        onMouseLeave={() => {

                            setShowMouseOver({
                                ...showMouseOver,
                                inventory: false
                            })
                        }}

                    >
                        {showMouseOver.inventory &&
                            <div className="btn-title">
                                Inventory
                            </div>
                        }
                        <div className="imgIcon">
                            <img src={inventory} alt="" />
                        </div>
                    </div>
                    <div className="dock-btn" style={{ marginLeft: "3px" }}
                        onMouseEnter={() => {
                            setAllFalse();
                            setShowMouseOver((prev) => ({
                                ...prev,
                                people: true,
                            }));
                        }}
                        onMouseLeave={() => {

                            setShowMouseOver({
                                ...showMouseOver,
                                people: false
                            })
                        }}

                    >
                        {showMouseOver.people &&
                            <div className="btn-title">
                                People
                            </div>
                        }
                        <div className="imgIcon">
                            <img src={people} alt="" />
                        </div>
                    </div>
                    <div className="dock-btn" style={{ marginLeft: "3px" }}
                        onMouseEnter={() => {
                            setAllFalse();
                            setShowMouseOver((prev) => ({
                                ...prev,
                                business: true,
                            }));
                        }}
                        onMouseLeave={() => {

                            setShowMouseOver({
                                ...showMouseOver,
                                business: false
                            })
                        }}

                    >
                        {showMouseOver.business &&
                            <div className="btn-title">
                                Businesses
                            </div>
                        }
                        <div className="imgIcon">
                            <img src={business} alt="" />
                        </div>
                    </div>
                    <div className="dock-btn" style={{ marginLeft: "3px" }}
                        onMouseEnter={() => {
                            setAllFalse();
                            setShowMouseOver((prev) => ({
                                ...prev,
                                stores: true,
                            }));
                        }}
                        onMouseLeave={() => {

                            setShowMouseOver({
                                ...showMouseOver,
                                stores: false
                            })
                        }}
                    >
                        {showMouseOver.stores &&
                            <div className="btn-title">
                                Locations
                            </div>
                        }
                        <div className="imgIcon">
                            <img src={locations} alt="" />
                        </div>

                    </div>
                    <div className="dock-btn" style={{ marginLeft: "3px" }}
                        onMouseEnter={() => {
                            setAllFalse();
                            setShowMouseOver((prev) => ({
                                ...prev,
                                rentals: true,
                            }));
                        }}
                        onMouseLeave={() => {

                            setShowMouseOver({
                                ...showMouseOver,
                                rentals: false
                            })
                        }}
                    >
                        {showMouseOver.rentals &&
                            <div className="btn-title" style={{ whiteSpace: 'nowrap' }}>
                                Vehicles
                            </div>
                        }
                        <div className="imgIcon">
                            <img src={vehicles} alt="" />
                        </div>
                    </div>

                    <div className="dock-btn" style={{ marginLeft: "3px" }}
                        onMouseEnter={() => {
                            setAllFalse();
                            setShowMouseOver((prev) => ({
                                ...prev,
                                furniture: true,
                            }));
                        }}
                        onMouseLeave={() => {
                            setShowMouseOver({
                                ...showMouseOver,
                                furniture: false
                            })
                        }}>

                        {showMouseOver.furniture &&
                            <div className="btn-title">
                                Furniture & Props
                            </div>
                        }
                        <div className="imgIcon">
                            <img src={furniture} alt="" />
                        </div>
                    </div>
                    <div className="dock-btn" style={{ marginLeft: "3px" }}
                        onMouseEnter={() => {
                            setAllFalse();
                            setShowMouseOver((prev) => ({
                                ...prev,
                                exchange: true,
                            }));
                        }}
                        onMouseLeave={() => {
                            setShowMouseOver({
                                ...showMouseOver,
                                exchange: false
                            })
                        }}>

                        {showMouseOver.exchange &&
                            <div className="btn-title">
                                Art
                            </div>
                        }
                        <div className="imgIcon">
                            <img src={art} alt="" />
                        </div>
                    </div><div className="dock-btn" style={{ marginLeft: "3px" }}
                        onMouseEnter={() => {
                            setAllFalse();
                            setShowMouseOver((prev) => ({
                                ...prev,
                                actors: true,
                            }));
                        }}
                        onMouseLeave={() => {
                            setShowMouseOver({
                                ...showMouseOver,
                                actors: false
                            })
                        }}>

                        {showMouseOver.actors &&
                            <div className="btn-title">
                                Actors
                            </div>
                        }
                        <div className="imgIcon">
                            <img src={actors} alt="" />
                        </div>
                    </div>
                </div>

                <div className="justify-content-end">
                    <div className="separator">
                    </div>
                    <Link to="/Desktop" className="noDecoration white">
                    <div className="dock-btn">
                    {showMouseOver.desktop &&
                        <div className="btn-title">
                        Desktop
                    </div>
                    }
                    
                    <div className={location.pathname == "/Desktop" ? "imgIcon active" : "imgIcon" }>
                            <img src={desktopIcon} alt=""
                    onMouseEnter={() => {

                        setAllFalse();
                        setShowMouseOver((prev) => ({
                            ...prev,
                            desktop: true,
                        }));

                    }} onMouseLeave={() => {
                        setShowMouseOver({
                            ...showMouseOver,
                            desktop: false
                        })
                    }}
                            />
                            </div>
                    </div>
                    </Link>
                    <div className="dock-btn">
                        {showMouseOver.notifications &&
                            <div className="btn-title">
                                Notifications
                            </div>

                        }<div className="imgIcon">
                            <img src={notificationsIcon} alt=""
                                onMouseEnter={() => {

                                    setAllFalse();
                                    setShowMouseOver((prev) => ({
                                        ...prev,
                                        notifications: true,
                                    }));

                                }} onMouseLeave={() => {
                                    setShowMouseOver({
                                        ...showMouseOver,
                                        notifications: false
                                    })
                                }}

                            />
                        </div>

                    </div>

                    <div className="dock-btn">
                        {showMouseOver.settings &&
                            <div className="btn-title">
                                Settings
                            </div>
                        }   <div className="imgIcon">
                            <img src={settingsIcon} alt="" onClick={() => { setShowSettings(true) }} onMouseEnter={() => { setShowMouseOver({ userMenu: false, lottery: false, settings: true, notifications: false }) }} onMouseLeave={() => { setShowMouseOver({ userMenu: false, lottery: false, settings: false, notifications: false }) }} />
                        </div>
                    </div>
                </div>

            </div>
            {showMouseOver.userMenu &&
                <UserMenu setShowWallets={setShowWallets} setShowMouseOver={setShowMouseOver} />
            }

        </>
    )
}

export default Dock
