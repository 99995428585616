

import { Row, Col } from 'react-bootstrap';
import plusIcon from "../../imgs/interface/add.png"
const ModifyUserInfo = ({ userInfo, setUserInfo, setEdit, fetchUser, handleInputChange, handleSaveUser }) => {
    


    const addAddress = () => {
        const newAddress = {
            name: "",
            address: "",
            city: "",
            state: "",
            country: "",
            postalCode: "",
            telephone: ""
        };

        setUserInfo({
            ...userInfo,
            userAddresses: [...userInfo.userAddresses, newAddress]
        });
    }

    const deleteAddress = (index) => {
        const updatedAddresses = [...userInfo.userAddresses];
        updatedAddresses.splice(index, 1);

        setUserInfo({
            ...userInfo,
            userAddresses: updatedAddresses,
        });
    };


    return (
        <>
            <div className="form-floating addInput">
                <input
                    type="text"
                    className="form-control"
                    id="userFirstName"
                    name="userFirstName"
                    placeholder="First Name"
                    onChange={handleInputChange}
                    value={userInfo.userFirstName}
                />
                <label htmlFor="userFirstName" style={{ color: "#898989" }}>
                    First Name
                </label>
            </div>

            <div className="form-floating mt-2">
                <input
                    type="text"
                    className="form-control"
                    id="userLastName"
                    name="userLastName"
                    placeholder="Last Name"
                    onChange={handleInputChange}
                    value={userInfo.userLastName}
                />
                <label htmlFor="userLastName" style={{ color: "#898989" }}>
                    Last Name
                </label>
            </div>
            <div className="form-floating mt-2">
                <input
                    type="text"
                    className="form-control"
                    id="userEmail"
                    name="userEmail"
                    placeholder="Email"
                    onChange={handleInputChange}
                    value={userInfo.userEmail}
                />
                <label htmlFor="userEmail" style={{ color: "#898989" }}>
                    Email
                </label>
            </div>
            <div className="form-floating mt-2">
                <input
                    type="text"
                    className="form-control"
                    id="userCell"
                    name="userCell"
                    placeholder="Cell Phone"
                    onChange={handleInputChange}
                    value={userInfo.userCell}
                />
                <label htmlFor="userCell" style={{ color: "#898989" }}>
                    Cell Phone
                </label>
            </div>
           
           
            <div className='subTitle'>
                <div className="texte">
                    Addresses
                </div>
                <div className="btn-Add" onClick={addAddress}>
                </div>
            </div>

            {userInfo.userAddresses && userInfo.userAddresses.map((address, index) => (

                <div key={index}>
                    <div className="form-floating mt-3">
                        <input
                            type="text"
                            className="form-control"
                            id={`name_${index}`}
                            name={`userAddresses.${index}.name`}
                            placeholder="Name"
                            onChange={handleInputChange}
                            value={address.name}
                        />
                        <label htmlFor={`name_${index}`} style={{ color: "#898989" }}>
                            Name
                        </label>
                    </div>

                    <div className="form-floating mt-2">
                        <input
                            type="text"
                            className="form-control"
                            id={`address_${index}`}
                            name={`userAddresses.${index}.address`}
                            placeholder="Address"
                            onChange={handleInputChange}
                            value={address.address}
                        />
                        <label htmlFor={`address_${index}`} style={{ color: "#898989" }}>
                            Street
                        </label>
                    </div>

                    <Row>
                        <Col xs={12} md={6} className="mt-2">
                            <div className="form-floating">
                                <input
                                    type="text"
                                    className="form-control"
                                    id={`city_${index}`}
                                    name={`userAddresses.${index}.city`}
                                    placeholder="City"
                                    onChange={handleInputChange}
                                    value={address.city}
                                />
                                <label htmlFor={`city_${index}`} style={{ color: "#898989" }}>
                                    City
                                </label>
                            </div>
                        </Col>
                        <Col xs={12} md={6}>
                            <div className="form-floating mt-2">
                                <input
                                    type="text"
                                    className="form-control"
                                    id={`state_${index}`}
                                    name={`userAddresses.${index}.state`}
                                    placeholder="State"
                                    onChange={handleInputChange}
                                    value={address.state}
                                />
                                <label htmlFor={`state_${index}`} style={{ color: "#898989" }}>
                                    State
                                </label>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} md={6} className="mt-2">
                            <div className="form-floating">
                                <input
                                    type="text"
                                    className="form-control"
                                    id={`country_${index}`}
                                    name={`userAddresses.${index}.country`}
                                    placeholder="Country"
                                    onChange={handleInputChange}
                                    value={address.country}
                                />
                                <label htmlFor={`country_${index}`} style={{ color: "#898989" }}>
                                    Country
                                </label>
                            </div>
                        </Col>
                        <Col xs={12} md={6}>
                            <div className="form-floating mt-2">
                                <input
                                    type="text"
                                    className="form-control"
                                    id={`postalCode_${index}`}
                                    name={`userAddresses.${index}.postalCode`}
                                    placeholder="Postal Code"
                                    onChange={handleInputChange}
                                    value={address.postalCode}
                                />
                                <label htmlFor={`postalCode_${index}`} style={{ color: "#898989" }}>
                                    Postal Code
                                </label>
                            </div>
                        </Col>
                    </Row>

                    <div className="form-floating mt-2">
                        <input
                            type="text"
                            className="form-control"
                            id={`telephone_${index}`}
                            name={`userAddresses.${index}.telephone`}
                            placeholder="Telephone"
                            onChange={handleInputChange}
                            value={address.telephone}
                        />
                        <label htmlFor={`telephone_${index}`} style={{ color: "#898989" }}>
                            Telephone
                        </label>
                    </div>
                    {index != 0 &&
                        <div className="my-btn-primary mt-3" onClick={() => deleteAddress(index)} style={{ width: "max-content", margin: "auto" }}>Remove</div>
                    }
                    <div className="subTitle"></div>
                </div>



            ))}


            <Row className='mt-3'>
                <Col>
                    <div className="my-btn-primary" onClick={handleSaveUser}>Save</div>
                </Col>
                <Col>
                    <div className="my-btn-primary" onClick={() => { 
                        fetchUser()
                        setEdit(false)
                         }}>Cancel</div>

                </Col>
            </Row>
        </>
    )


}


export default ModifyUserInfo