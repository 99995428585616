import { useState, useEffect } from "react";
import "../css/subscribe.css";
import logo from "../imgs/interface/logo_small.png";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Subscribe = ({ setSignupInfo }) => {
  const [t, i18n] = useTranslation("global");

  // Using local state in Subscribe component
  const [localState, setLocalState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    country: "",
  });

  // Update parent component's state when local state changes
  useEffect(() => {
    setSignupInfo((prevSignupInfo) => ({
      ...prevSignupInfo,
      ...localState,
    }));
  }, [localState, setSignupInfo]);

  return (
    <div className="subscribe">
      <img src={logo} alt="" />
      <h5 className="mt-4 mb-4">{t("header.btn-subscribe")}</h5>
      <form>
        <div className="form-floating">
          <input
            type="text"
            style={{ borderRadius: "5px 5px 0px 0px" }}
            className="form-control"
            name="firstName"
            placeholder="firstName"
            onChange={(e) => setLocalState((prevState) => ({ ...prevState, firstName: e.target.value }))}
            value={localState.firstName}
            required
          />
          <label htmlFor="floatingInput">{t("formLabels.firstName")}</label>
        </div>
        <div className="form-floating">
          <input
            type="text"
            style={{ borderRadius: "0px" }}
            className="form-control"
            name="lastName"
            placeholder="lastName"
            onChange={(e) => setLocalState((prevState) => ({ ...prevState, lastName: e.target.value }))}
            value={localState.lastName}
            required
          />
          <label htmlFor="floatingInput">{t("formLabels.lastName")}</label>
        </div>
        <div className="form-floating">
          <input
            type="email"
            style={{ borderRadius: "0px 0px 5px 5px" }}
            className="form-control"
            placeholder="email"
            onChange={(e) => setLocalState((prevState) => ({ ...prevState, email: e.target.value }))}
            value={localState.email}
            required
          />
          <label htmlFor="floatingInput">{t("formLabels.email")}</label>
        </div>
        <Link to="/Signup">
        <button className="my-btn-primary w-100 mt-4">
          {t("header.btn-subscribe")}
        </button>
        </Link>
      </form>
    </div>
  );
};

export default Subscribe;
