



const Admin = () =>{

    return(
        <div>Admin</div>
    )
}

export default Admin