
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from './components/header';
import { useTranslation } from 'react-i18next';
import Signup from './pages/signup';
import HomePage from './pages/homePage';
import NotVerified from './components/system/notVerified';
import VerifiedEmail from './components/system/verified';
import Signin from './pages/signin';
import Desktop from './pages/desktop';
import Account from './pages/account';
import { useGlobalContext } from './hooks/useGlobalContext';
import Admin from './pages/admin';
import Dock from './components/system/dock';


function App() {

  const { user, userDispatch } = useGlobalContext()

  const [signupInfo, setSignupInfo] = useState({
    firstName: "",
    lastName: "",
    email: ""
  })

  useEffect(() => {
    const storedUser = localStorage.getItem('user');

    if (storedUser) {
      try {
        const parsedUser = JSON.parse(storedUser);
        
        userDispatch({ type: 'LOGIN', payload: parsedUser });
        
      } catch (error) {
        console.error('Error parsing user data:', error);
      }
    }

  }, []);


  // Function to determine the language based on keyboard layout
  // Function to determine the language based on keyboard layout
  const getLanguageFromKeyboard = () => {
    const keyboardLanguage = navigator.language.substring(0, 2).toLowerCase();
    switch (keyboardLanguage) {
      case 'fr':
        return 'fr';
      case 'es':
        return 'es';
      default:
        return 'en';
    }
  };


  const { i18n } = useTranslation();

  useEffect(() => {
    const preferredLanguage = getLanguageFromKeyboard();
    i18n.changeLanguage(preferredLanguage);


  }, [i18n]);


  return (
    <div className="App">

      <BrowserRouter>

        <Header />

        <Routes>

          <Route path="/" element={<HomePage setSignupInfo={setSignupInfo} />} />
          <Route path="/Signup" element={<Signup signupInfo={signupInfo} setSignupInfo={setSignupInfo} />} />
          <Route path="/Signin" element={<Signin />} />
          <Route path="/Desktop" element={<Desktop />} />
          <Route path="/Account" element={<Account />} />
          <Route path="/Admin" element={<Admin />} />


          <Route path="/NotVerified" element={<NotVerified />} />
          <Route path="/Verified" element={<VerifiedEmail />} />

        
        </Routes>
        {user &&
        <Dock />
        }
      </BrowserRouter>





      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={true}
        closeOnClick={true}
        pauseOnHover={true}
        draggable={true}
        progress={undefined}
        theme="colored"
      />
    </div>
  );


}

export default App;