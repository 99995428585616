
import "../../css/dock.css"
import adminIcon from "../../imgs/interface/admin.png"
import dashboardIcon from "../../imgs/admin.png"
import account from "../../imgs/interface/account.png"

import signOutIcon from "../../imgs/signOut.png"

import orders from "../../imgs/interface/orders.png"
import invoices from "../../imgs/interface/invoices.png"


import { useGlobalContext } from "../../hooks/useGlobalContext"
import { Link, useNavigate } from "react-router-dom"

const UserMenu = ({ setShowMouseOver, setShowWallets }) => {

    const { user, userDispatch } = useGlobalContext()

    const navigate = useNavigate()


    const signOut = () => {
        localStorage.removeItem("user");
        userDispatch({ type: "LOGOUT" });
        navigate("/")
    }

    return (
        <div className="userMenu" onMouseLeave={() => { setShowMouseOver({ userMenu: false, lottery: false, settings: false, notifications: false }) }}>
            <div className="title">
                {user.userFirstName} {user.userLastName}
            </div>
           
           

            <Link to="/Account" className="noDecoration white">
                <div className="menu-btn">
                    <div className="texte">
                        Account
                    </div>
                    <img src={account} alt="" />
                </div>
            </Link>
            <div className="menu-btn">
                <div className="texte">
                    Dashboard
                </div>
                <img src={dashboardIcon} alt="" />
            </div>
            <div className="menu-btn">
                <div className="texte">
                    Orders
                </div>
                <img src={orders} alt="" />
            </div>
            <div className="menu-btn" onClick={() => {
                setShowWallets(true)
            }}>
                <div className="texte">
                    Invoices
                </div>
                <img src={invoices} alt="" />
            </div>
            <div className="separatorH">

            </div>
            {user.userAdmin &&
            <Link to="/Admin" className="noDecoration white">
                <div className="menu-btn">
                    <div className="texte">
                        Admin
                    </div>
                    <img src={adminIcon} alt="" />
                </div>
                </Link>
            }
            <div className="menu-btn" onClick={() => { signOut() }}>
                <div className="texte">Sign out</div>
                <img src={signOutIcon} alt="" />
            </div>
        </div>
    )

}

export default UserMenu