import { GlobalContext } from "../context/GlobalContextProvider";
import { useContext } from "react";

export const useGlobalContext = () => {
  const context = useContext(GlobalContext);

  if (!context) {
    throw Error("Not Working...");
  }

  return context;
};
