import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import SignupForm from '../components/system/signup';
import { useState } from 'react';

const Signup = ({signupInfo, setSignupInfo}) =>{

    const [t, i18n] = useTranslation('global');



    return(
        <div className="fullscreen">
            
            <div className="signup">
                <Row>
                    <Col xs={12} md={6} style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
                    <div>
                    <h3 className="right">{t("signup.subTitle")}</h3>
                    <p className='right'>{t("signup.description")}</p>
                    </div>
                    
                    </Col>
                    <Col className="left">
                    
                    
                    <SignupForm signupInfo={signupInfo} setSignupInfo={setSignupInfo} />
                    
                    </Col>
                </Row>
            </div>


        </div>
    )

}

export default Signup