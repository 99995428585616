import { useTranslation } from "react-i18next";
import "../css/header.css";

import logo from "../imgs/interface/logo_small.png";
import { Link, useLocation } from "react-router-dom";

const Header = () => {
  const location = useLocation();
  const [t, i18n] = useTranslation("global");

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <header>
     
      <div>{location.pathname == "/" && (
          <>
           <div className="smallLogo">
        <Link to="/">
          <img src={logo} height="30px" alt="" />
        </Link>
      </div>
        <select onChange={(e) => changeLanguage(e.target.value)} value={i18n.language}>
          <option className="option" value="en">
            En
          </option>
          <option value="es">Es</option>
          <option value="fr">Fr</option>
        </select>


          </>
        )}
        {/* Conditionally render the Signup button link */}
        {location.pathname == "/" && (
          <>


            <Link to="/Signup">
              <button style={{ float: "right" }} className="my-btn-primary">
                {t("header.btn-subscribe")}
              </button>
            </Link>
          </>
        )}
      </div>
    </header>
  );
};

export default Header;
