
import "../../css/account.css"

const UserInfo = ({ userInfo }) => {


    return (
        <>
                <div className="infos">
                <div className="name">{userInfo.userFirstName} {userInfo.userLastName}</div>
                <div >{userInfo.userEmail}</div>
                <div >{userInfo.userCell}</div>

                </div>
                
           
            {userInfo.userAddresses && userInfo.userAddresses.map((address, index) => {

                return (
                    <div key={index} className="addressCard">
                        <div className="name">{address.name}</div>
                        <div>{address.address}</div>
                        <div>{address.city}, {address.state}</div>
                        <div>{address.country}, {address.postalCode}</div>
                        <div>{address.telephone}</div>

                    </div>
                )

            })}


        </>
    )


}


export default UserInfo