
import logo from '../../imgs/interface/logo_small.png'
import { useNotify } from '../../hooks/useNotify';

import config from '../../config'
import { useTranslation } from 'react-i18next';

const NotVerified = () => {

    const [t, i18n] = useTranslation('global');
    
    const {notify} = useNotify()
    const handleClick = async () => {
        
        const token = localStorage.getItem('token');
        
        const notUser = localStorage.getItem('notUser');
        const userEmail = notUser.email
        const userPassword = notUser.password
        
        

        const response = await fetch( `${config.BASE_URL}/api/user/resend-verification-email`, {
            method: 'POST',
            headers: {'content-type' : 'application/json',
            'Authorization': `Bearer ${token}`},
            body:JSON.stringify({ userEmail, userPassword})
        })
            .then(res => res.json())
            .then(data => {
                notify('success', "An new eamil as been sent to your addresse")
                console.log(data.message);
            })
            .catch(err => {
                console.log(err);
            });
    }


    return (
        <div className="fullscreen">
            <div className="notVerified">
            <h3 className='text-center mt-3 mb-3'>{t("notVerified.welcome")}</h3>
                <div className='text-center'>
                    <img src={logo} className="logoEmail" alt="" />
                </div>
                <p  className='text-center mt-3'>{t("notVerified.message")}</p>
                <h3 className='text-center mb-4 mt-4'>{t("notVerified.thanks")}</h3>
                <div  className='text-center'>{t("notVerified.didNotReceive")}</div>

                <div  className='text-center mt-4 mb-4 d-flex justify-content-center'>
                    <div className="btn-login" onClick={handleClick}>
                    {t("notVerified.resend")}</div>
                </div>
            </div>
                
            
        </div>
    )

}


export default NotVerified