import { Col, Row } from "react-bootstrap"

const Preferences = ({ handleInputChange, modifyUser }) => {

    return (
        <div className="accountTab">
            <div className="subTitle mt-4">
                <div className="texte">
                    Preferences
                </div>

            </div>
            <Row className="mt-4" style={{ margin: "auto", maxWidth: "600px" }}>
                <Col xs={4} md={4} style={{ textAlign: "right" }}>
                    Languages :
                </Col>
                <Col style={{ textAlign: "left" }}>
                    English
                </Col>
            </Row>
            <Row className="mt-4" style={{ margin: "auto", maxWidth: "600px" }}>
                <Col xs={4} md={4} style={{ textAlign: "right" }}>
                    <div style={{ marginTop: "22px" }}>

                        Stripe :
                    </div>

                </Col>
                <Col style={{ textAlign: "left", display: "flex" }}>
                    <div className="form-floating mt-2 w-100">
                        <input
                            type="text"
                            className="form-control"
                            id="stripe"
                            name="stripe"
                            placeholder="Stripe"
                            onChange={handleInputChange}
                            value={modifyUser.stripe}
                        />
                        <label htmlFor="stripe" style={{ color: "#898989" }}>
                            Stripe Account
                        </label>
                    </div>
                    <div className="btn-Save" style={{ marginTop: "22px", marginLeft: "10px" }}>

                    </div>
                </Col>
            </Row>
            <Row className="mt-3" style={{ maxWidth: "600px", margin: "auto" }}>
                <Col xs={4} md={4} style={{ textAlign: "right" }}>

                </Col>
                <Col style={{ textAlign: "left" }}>
                    <div>
                        <label style={{ display: "flex" }}>
                            <input
                                type="checkbox"
                                required
                            />
                            <div style={{ marginLeft: "10px" }}>Show Profile in People</div>


                        </label>


                    </div>
                    <div>
                        <label style={{ display: "flex" }}>
                            <input
                                type="checkbox"
                                required
                            />
                            <div style={{ marginLeft: "10px" }}>Show Projetcs in Profile</div>


                        </label>


                    </div>
                    <div>
                        <label style={{ display: "flex" }}>
                            <input
                                type="checkbox"
                                required
                            />
                            <div style={{ marginLeft: "10px" }}>Share my locations with teammates</div>


                        </label>


                    </div>
                    <div>
                        <label style={{ display: "flex" }}>
                            <input
                                type="checkbox"
                                required
                            />
                            <div style={{ marginLeft: "10px" }}>I'm an Agent</div>
                        </label>
                    </div>
                </Col>
            </Row>
        </div>
    )


}

export default Preferences