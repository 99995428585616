import { createContext } from "react";

export const BusinessContext = createContext();

export const businessReducer = (state, action) => {
  switch (action.type) {
    case "ADD":
      return {
        businesses:[action.payload, ...state.businesses]
      };
    case "REMOVE":
      return {
        businesses: state.businesses.filter((business) => business._id = action.payload._id)

      };
    case "ADD_ALL":
      return {
        businesses: action.payload,
      };
    default:
      return state;
  }
};
