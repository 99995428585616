

const Subscribtions = () =>{

    return(
        <div className="subTitle">
            <div className="texte">
                Subscriptions
            </div>
        </div>
    )


}

export default Subscribtions