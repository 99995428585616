import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../hooks/useGlobalContext";
import { useEffect, useState } from "react";
import Dock from "../../components/system/dock";
import editIcon from "../../imgs/interface/editOn.png"
import saveIcon from "../../imgs/interface/save.png"

import "../../css/account.css"
import { Row, Col } from 'react-bootstrap';
import { useNotify } from "../../hooks/useNotify";


import plus from "../../imgs/interface/add.png"


import config from "../../config"
import ModifyUserInfo from "../../components/account/modifyUserInfo";
import UserInfo from "../../components/account/userInfo";

import UserIcon from "../../components/account/userIcon";
import Security from "../../components/account/securityTab";
import Loader from "../../components/system/loader";
import Preferences from "../../components/account/preferencesTab";
import Subscribtions from "../../components/account/subscribtions";

const Account = () => {
  const { user, userDispatch } = useGlobalContext();
  const navigate = useNavigate();
  const [editInformation, setEditInformation] = useState(false)

  const [modifyUser, setModifyUser] = useState()


  const [isLoading, setIsLoading] = useState(true)

  const { notify } = useNotify()

  const fetchUser = async () => {
    const response = await fetch(
      `${config.BASE_URL}/api/user/MesInfos`, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    });
    const json = await response.json();
    setModifyUser(json)
    setIsLoading(false)
  }


  const generateAvatar = async () => {
    const gender = { gender: modifyUser.userGender };
    console.log("I'm in", gender);

    try {
      const avatarUrl = await fetch(`${config.BASE_URL}/api/user/generateAvatar`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.token}`,
        },
        body: JSON.stringify(gender), // Pass gender directly without wrapping it in an object
      });

      if (avatarUrl.ok) {
        const json = await avatarUrl.json();
        setModifyUser({ ...modifyUser, avatar: json.url });
      } else {
        console.error('Failed to fetch avatar URL');
      }
    } catch (error) {
      console.error('Error during fetch:', error);
    }
  };


  const handleSaveUser = async () => {
    console.log("save USer")
    try {
      const saveUser = await fetch(`${config.BASE_URL}/api/user/MesInfos/${modifyUser._id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.token}`,
        },
        body: JSON.stringify(modifyUser),
      });

      if (saveUser.ok) {
        const json = await saveUser.json();
        setEditInformation(false)
        console.log("User from global : ", user)
        console.log("Json from back : ", json)

        const userWithToken = {
          ...json,
          token: user.token, // Use the existing token from the global state
        };
  
        // Update the global state with the new user information including the token
        userDispatch({ type: "SAVE_USER", payload: userWithToken });
        if(localStorage.getItem("user"))
        {
          localStorage.setItem('user', JSON.stringify(userWithToken))
        }
        notify("success", "Information saved successfully.")
      } else {
        console.error('Failed to save user information');
      }
    } catch (error) {
      console.error('Error during fetch:', error);
    }
  }

  useEffect(() => {
    if (!user && !localStorage.getItem('user')) {
      navigate("/Signin");
    }
    const userLocal = JSON.parse(localStorage.getItem('user')); // Parse the stored string
    userDispatch({ type: 'LOGIN', payload: userLocal });

  }, [navigate]);


  useEffect(() => {
    if (user) {
      fetchUser()
    }

  }, [user])


  useEffect(() => {

    console.log("Modify Iser", modifyUser)
  }, [modifyUser])



  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name.startsWith("userAddresses.")) {
      const addressIndex = name.split(".")[1];
      const addressField = name.split(".")[2];

      // Copy the existing addresses array
      const updatedAddresses = [...modifyUser.userAddresses];

      // Update the specific address field
      updatedAddresses[addressIndex] = {
        ...updatedAddresses[addressIndex],
        [addressField]: value
      };

      setModifyUser({
        ...modifyUser,
        userAddresses: updatedAddresses
      });

    } else {
      setModifyUser({ ...modifyUser, [name]: value });

    }
  };


  return (
    <>
      {isLoading &&
         <Loader />
      }
      {!isLoading &&
        <div className="account">
          <div className="container">
            <div className="subTitle mt-4">
              <div className="texte">
                Personnal Information
              </div>
              {!editInformation &&
                <div className="btn-Edit"
                  title="Edit personnal information"
                  onClick={() => {

                    setEditInformation(true)

                  }}>
                </div>

              }
              {editInformation &&
                <div className="btn-Save"
                  title="Save changes"
                  onClick={() => {

                    handleSaveUser()

                  }}>

                </div>
              }

            </div>
            {modifyUser &&
              <Row className="mt-4" style={{ margin: "auto", maxWidth: "600px" }}>
                <Col xs={12} md={4} className="mb-3">
                  <UserIcon handleInputChange={handleInputChange} userInfo={modifyUser} generateAvatar={generateAvatar} setUser={setModifyUser} editUser={editInformation} />
                </Col>
                <Col xs={12} md={8}>
                  {editInformation &&
                    <ModifyUserInfo handleSaveUser={handleSaveUser} handleInputChange={handleInputChange} fetchUser={fetchUser} userInfo={modifyUser} setUserInfo={setModifyUser} setEdit={setEditInformation} />
                  }
                  {!editInformation &&
                    <UserInfo userInfo={modifyUser} />
                  }


                </Col>
              </Row>

            }



            
            <Preferences modifyUser={modifyUser} handleInputChange={handleInputChange} />
            
            <Security />

            <Subscribtions />
            
            <div className="subTitle mt-4">
              <div className="texte">
                My Projects
              </div>
              <div className="btn-Add" title="Add">

              </div>
            </div>
            <div className="subTitle mt-4">
              <div className="texte">
                My Teammates
              </div>
              <div className="btn-Add" title="Add">

              </div>
            </div>
            <div className="subTitle mt-4">
              <div className="texte">
                My Businesses
              </div>
              <div className="btn-Add" title="Add">

              </div>
            </div>
            <div className="subTitle mt-4">
              <div className="texte">
                My Locations
              </div>
              <div className="btn-Add" title="Add">

              </div>
            </div>
            <div className="subTitle mt-4">
              <div className="texte">
                My Vehicles
              </div>
              <div className="btn-Add" title="Add">

              </div>
            </div>
            <div className="subTitle mt-4">
              <div className="texte">
                My Funiture & Props
              </div>
              <div className="btn-Add" title="Add">

              </div>
            </div>
            <div className="subTitle mt-4">
              <div className="texte">
                My Art
              </div>
              <div className="btn-Add" title="Add">

              </div>
            </div>


          </div>
        </div>
      }
      
    </>

  )
}

export default Account