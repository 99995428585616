import React from 'react';
import { useTranslation } from 'react-i18next';
import { Carousel, Container, Row, Col } from 'react-bootstrap';

import logo from '../imgs/interface/logo_big.png';
import slide2 from '../imgs/interface/inventory.png';
import slide3 from '../imgs/interface/project.png';
import slide5 from '../imgs/interface/tailored.png';
import slide6 from '../imgs/interface/rent.png';
import slide4 from '../imgs/interface/AI.png';
import Subscribe from '../components/subscribe';



const HomePage = ({setSignupInfo}) => {
    const [t, i18n] = useTranslation('global');

    return (
        <div>
            <div className="intro">
                <Carousel interval="8000">
                    {/* First Slide */}
                    <Carousel.Item>
                        <Container>
                            <img src={logo} className='logo' alt="" />
                            <h2 className='mt-3'>{t('slideshow.slide1.title')}</h2>
                            <p className='mt-2'>{t('slideshow.slide1.description')}</p>
                        </Container>
                    </Carousel.Item>

                    {/* Second Slide */}
                    <Carousel.Item>
                        <Container>
                            <Row className="slideRow">
                            <Col xs={12} md={6} className="slideCol">
                                <img src={slide2} className="slideImg" alt="" />
                                </Col> 
                                <Col xs={12} md={6} className="slideCol">
                                <h3>{t('slideshow.slide2.title')}</h3>
                                <p>{t('slideshow.slide2.description')}</p>
                                </Col>
                               
                            </Row>
                        </Container>
                    </Carousel.Item>

                    {/* Third Slide */}
                    <Carousel.Item>
                        <Container>
                            <Row className="slideRow">
                           
                                <Col xs={12} md={6} className="slideCol">
                                <h3>{t('slideshow.slide3.title')}</h3>
                            <p>{t('slideshow.slide3.description')}</p>
                                </Col>
                                <Col xs={12} md={6} className="slideCol">
                                <img src={slide3} className="slideImg" alt="" />
                                </Col>     
                            </Row>
                            
                        </Container>
                    </Carousel.Item>
                    <Carousel.Item>
                        <Container>
                            <Row className="slideRow">
                            <Col xs={12} md={6} className="slideCol">
                                <img src={slide4} className="slideImg" alt="" />
                                </Col> 
                                <Col xs={12} md={6} className="slideCol">
                                <h3>{t('slideshow.slide4.title')}</h3>
                            <p>{t('slideshow.slide4.description')}</p>
                                </Col>
                               
                            </Row>
                        </Container>
                    </Carousel.Item>
                    <Carousel.Item>
                        <Container>
                            <Row className="slideRow">
                           
                            <Col xs={12} md={6} className="slideCol">
                            <h3>{t('slideshow.slide5.title')}</h3>
                            <p>{t('slideshow.slide5.description')}</p>    
                                </Col>
                                <Col xs={12} md={6} className="slideCol">
                                <img src={slide5} className="slideImg" alt="" />
                            </Col>   
                            </Row>
                            
                        </Container>
                    </Carousel.Item>
                    <Carousel.Item>
                        <Container>
                            <Row className="slideRow">
                            <Col xs={12} md={6} className="slideCol">
                            <img src={slide6} className="slideImg" alt="" />
                            </Col>
                            <Col xs={12} md={6} className="slideCol">
                            <h3>{t('slideshow.slide6.title')}</h3>
                            <p>{t('slideshow.slide6.description')}</p>
                            
                            </Col>
                            
                            </Row>
                            
                            
                        </Container>
                    </Carousel.Item>
                    <Carousel.Item>
                        <Container>
                            <Row className="slideRow">
                            <Col xs={12} md={6} className="slideCol hideText">
                            <h3>{t('slideshow.slide7.title')}</h3>
                            <p>{t('slideshow.slide7.description')}</p>
                            
                            </Col>
                            <Col xs={12} md={6} className="slideCol">
                                <Subscribe setSignupInfo={setSignupInfo} />
                            </Col>
                            </Row>
                            
                            
                        </Container>
                    </Carousel.Item>
                </Carousel>
            </div>

           
        </div>
    );
};

export default HomePage;
