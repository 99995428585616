import { Col, Row } from "react-bootstrap"
import SigninForm from "../components/system/signin"
import { useTranslation } from "react-i18next";
import { useGlobalContext } from "../hooks/useGlobalContext";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";


const Signin = () =>{

    const [t, i18n] = useTranslation('global');

    const {user} = useGlobalContext()
    const navigate = useNavigate()


    useEffect(() => {
        if (user || localStorage.getItem('user')) {
          navigate("/Desktop");
        }
      }, [user, navigate]);


    return(
        <div className="fullscreen">
            <div className="signup">
                <Row>
                    <Col xs={12} md={6} style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
                    <div>
                    <h3 className="right">{t("signin.subTitle")}</h3>
                    <p className='right'>{t("signin.description")}</p>
                    </div>
                    
                    </Col>
                    <Col className="left">
                    
                    
                    <SigninForm />
                    
                    </Col>
                </Row>
            </div>
            

        </div>
    )

}

export default Signin