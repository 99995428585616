import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { GlobalContextProvider } from './context/GlobalContextProvider';
import { I18nextProvider, useTranslation } from 'react-i18next';

import global_en from "./translations/en/global.json"
import global_fr from "./translations/fr/global.json"
import global_es from "./translations/es/global.json"

import i18next from "i18next"

i18next.init({
  interpolation: { escapeValue: true },
  lng: "en",
  resources: {
    en: {
      global: global_en
    },
    fr: {
      global: global_fr
    },
    es: {
      global: global_es
    }
  }
});

const rootElement = document.getElementById('root');


// Wrap the app in a root and render it
createRoot(rootElement).render(
  <GlobalContextProvider>
    <I18nextProvider i18n={i18next}> 
      <App />
    </I18nextProvider>
  </GlobalContextProvider>
);
