import { createContext, useReducer } from 'react'
import { categoriesReducer } from './CategoryContext';
import { userReducer } from './userContext';
import { businessReducer } from './businessContext';

export const GlobalContext = createContext()


export const GlobalContextProvider = ({ children }) => {

   
    const [categoriesState, categoriesDispatch] = useReducer(categoriesReducer, {
        categories: null  
      });  
      const [userState, userDispatch] = useReducer(userReducer, {
        user: null
      });
      const [businessState, businessDispatch] = useReducer(businessReducer, {
        businesses: null
      });



    return (
        <GlobalContext.Provider value={{...userState, userDispatch, ...businessState, businessDispatch, ...categoriesState, categoriesDispatch}}>
            { children }
        </GlobalContext.Provider>
    )

}