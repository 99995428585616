import { createContext } from 'react'

export const CategoryContext = createContext()

export const categoriesReducer = (state, action) => {
    switch(action.type){
        case 'SET_CATEGORIES':
            return {
                categories: action.payload.sort(function(a, b) {
                    return (a.Name > b.Name) - (a.Name < b.Name);
                    })
            }
            case 'SET_CATEGORY':
                console.log("Payload", action.payload)
                //const updatedCategory = state.categories.filter((category)=>category._id === action.payload._id)
                //updatedCategory.Name

                const updatedCategories = state.categories.map(category => {
                    if (category._id === action.payload._id) {
                        return { ...category, Name: action.payload.Name };
                    }
                    return category;
                });
    
                return {
                    ...state,
                    categories: updatedCategories
                };
        case 'CREATE_CATEGORY':
            console.log("Creating category")
            return {
                categories:[action.payload, ...state.categories]
            }
        case 'DELETE_CATEGORY':
            console.log("Deleting category")
            return {
                categories: state.categories.filter((c) => c._id !== action.payload._id)
            }
                
        default:
            return state
    }
}
