
import { useTranslation } from "react-i18next";
import logo from "../../imgs/interface/logo_small.png"
import { useState } from "react";
import { useNotify } from "../../hooks/useNotify";
import { useSignup } from "../../hooks/useSignup";

const SignupForm = ({ signupInfo }) => {
  const [t, i18n] = useTranslation('global');
  const { signup, isLoading, error } = useSignup();
  const [userFirstName, setUserFirstName] = useState(signupInfo.firstName)
  const [userLastName, setUserLastName] = useState(signupInfo.lastName)
  const [userEmail, setUserEmail] = useState(signupInfo.email)
  const [userPassword, setUserPassword] = useState("")
  const [userPassword2, setUserPassword2] = useState("")
  const [userCity, setUserCity] = useState("")
  const [userCountry, setUserCountry] = useState("")
  const [aggreeMailing, setAggreeMailing] = useState(false)
  const [aggreeTerms, setAggreeTerms] = useState(false)
  const [userGender, setUSerGender] = useState("male")
  const { notify } = useNotify();
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!aggreeTerms) {
      notify("error", t("errors.auth.signup.terms"));
    } else if (userPassword != userPassword2) {
      notify(
        "error",
        t("errors.auth.signup.samePassword")
      );
    } else {

      await signup(
        userFirstName,
        userLastName,
        userEmail,
        userPassword,
        userCity,
        userCountry,
        userGender,
        aggreeMailing,
        i18n.language

      );

    }
  };

  const handleChangeGender = (e) => {
    setUSerGender(e.target.value);
  };

  return (
    <div className="subscribe">
      <img src={logo} alt="" />
      <h5 className=" mt-3">{t("signup.title")}</h5>
      <form
        onSubmit={function (e) {
          handleSubmit(e);
        }}
      >
        <div className="form-floating mt-3">
          <input
            type="text"
            style={{ borderRadius: "5px 5px 0px 0px" }}
            className="form-control"
            name="firstName"
            placeholder="firstName"
            onChange={(e) => setUserFirstName(e.target.value)}
            value={userFirstName}
            required
          />
          <label htmlFor="floatingInput">{t("formLabels.firstName")}</label>
        </div>
        <div className="form-floating">
          <input
            type="text"
            style={{ borderRadius: "0px" }}
            className="form-control"
            name="lastName"
            placeholder="lastName"
            onChange={(e) => setUserLastName(e.target.value)}
            value={userLastName}
            required
          />
          <label htmlFor="floatingInput">{t("formLabels.lastName")}</label>
        </div>
        <div className="form-floating">
          <input
            type="email"
            style={{ borderRadius: "0px" }}
            className="form-control"
            placeholder="email"
            onChange={(e) => setUserEmail(e.target.value)}
            value={userEmail}
            required
          />
          <label htmlFor="floatingInput">{t("formLabels.email")}</label>
        </div>
        <div className="form-floating">
          <input
            type="password"
            style={{ borderRadius: "0px" }}
            className="form-control"
            name="password"
            placeholder="password"
            onChange={(e) => setUserPassword(e.target.value)}
            value={userPassword}
            required
          />
          <label htmlFor="floatingInput">{t("formLabels.password")}</label>
        </div>
        <div className="form-floating">
          <input
            type="password"
            style={{ borderRadius: "0px 0px 5px 5px" }}
            className="form-control"
            name="password2"
            placeholder="password2"
            onChange={(e) => setUserPassword2(e.target.value)}
            value={userPassword2}
            required
          />
          <label htmlFor="floatingInput">{t("formLabels.password2")}</label>
        </div>


        <div className="form-floating mt-2">
          <input
            type="text"
            style={{ borderRadius: "5px 5px 0px 0px" }}
            className="form-control"
            name="city"
            placeholder="city"
            onChange={(e) => setUserCity(e.target.value)}
            value={userCity}
          />
          <label htmlFor="floatingInput">{t("formLabels.city")}</label>
        </div>
        <div className="form-floating">
          <input
            type="text"
            style={{ borderRadius: "0px 0px 5px 5px" }}
            className="form-control"
            name="country"
            placeholder="country"
            onChange={(e) => setUserCountry(e.target.value)}
            value={userCountry}
          />
          <label htmlFor="floatingInput">{t("formLabels.country")}</label>
        </div>
        <div  title="Avatar">
          <div className="form-check form-check-inline mt-3">
            <input
              type="radio"
              className="form-check-input"
              id="maleRadio"
              name="gender"
              value="male"
              onChange={handleChangeGender}
              checked={userGender === "male"}
            />
            <label className="form-check-label" htmlFor="maleRadio">
              {t("formLabels.male")}
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              type="radio"
              className="form-check-input"
              id="femaleRadio"
              name="gender"
              value="female"
              onChange={handleChangeGender}
              checked={userGender === "female"}
            />
            <label className="form-check-label" htmlFor="femaleRadio">
              {t("formLabels.female")}
            </label>
          </div>
        </div>
        <div className="checkbox" style={{ marginTop: "5px", width: "100%", textAlign: "center", fontSize: "12px" }}>
          <label>
            <input
              type="checkbox"
              onChange={(e) => setAggreeMailing(e.target.checked)}
              checked={aggreeMailing}
            />{" "}
            {t("formLabels.mailing")}
          </label>
        </div>
        <div className="checkbox mb-2" style={{ marginTop: "5px", width: "100%", textAlign: "center", fontSize: "12px" }}>
          <label>
            <input
              type="checkbox"
              onChange={(e) => setAggreeTerms(e.target.checked)}
              checked={aggreeTerms}
              required
            />{" "}
            {t("formLabels.agreeTerms")}
            <a href="#" id="btnTerme" style={{ color: 'rgb(210, 157, 75)' }}>
              {t("formLabels.terms")}
            </a>
          </label>
        </div>

        <button className="my-btn-primary w-100 mt-2" type="submit">
          {t("header.btn-subscribe")}
        </button>

      </form>
    </div>
  )

}

export default SignupForm