import { useEffect } from 'react';
import config from "../../config";
import { useGlobalContext } from '../../hooks/useGlobalContext';

import refresh from "../../imgs/interface/refresh.png"
import upload from "../../imgs/interface/upload.png"
import remove from "../../imgs/interface/trash.png"


const UserIcon = ({ userInfo, setUser, generateAvatar, editUser, handleInputChange }) => {



    return (
        <div className="">
            <div className="imgUser">
                <img src={userInfo.avatar} alt="" />
            </div>

            {editUser &&
                <>
                    <div className="mt-3 w-100 d-flex justify-content-center">
                        <div className="form-check form-check-inline ">
                            <input
                                type="radio"
                                className="form-check-input"
                                id="maleRadio"
                                name="userGender"
                                value="male"
                                onChange={handleInputChange}
                                checked={userInfo.userGender === "male"}
                            />
                            <label className="form-check-label" htmlFor="maleRadio">
                                Male
                            </label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                type="radio"
                                className="form-check-input"
                                id="femaleRadio"
                                name="userGender"
                                value="female"
                                onChange={handleInputChange}
                                checked={userInfo.userGender === "female"}
                            />
                            <label className="form-check-label" htmlFor="femaleRadio">
                                Female
                            </label>
                        </div>
                    </div>
                    <div className="w-100 mt-2 d-flex justify-content-center">
                        <div className="btn-imgIcon" onClick={generateAvatar} title="Change Avantar">
                            <img src={refresh} alt="" className="" />
                        </div>
                        <div className="btn-imgIcon" title="Upload Image">
                            <img src={upload} alt="" className="" />
                        </div>
                        {userInfo.customAvatar &&
                            <div className="btn-imgIcon" title="Remove Image">
                                <img src={remove} alt="" className="" />
                            </div>
                        }
                    </div>
                </>

            }

        </div>
    );
};

export default UserIcon;
