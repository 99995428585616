import { useState } from "react";
import { useNotify } from "./useNotify";
import config from "../config";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const useSignup = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [t, i18n] = useTranslation('global');
  const navigate = useNavigate();
  const { notify } = useNotify();

  const signup = async (
    userFirstName,
    userLastName,
    userEmail,
    userPassword,
    userCity,
    userCountry,
    userGender,
    userMailing,
    userLanguage
  ) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(`${config.BASE_URL}/api/user/signup`, {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify({
          userFirstName,
          userLastName,
          userEmail,
          userPassword,
          userCity,
          userCountry,
          userGender,
          userMailing,
          userLanguage,
        }),
      });

      const json = await response.json();

      if (!response.ok) {
        throw new Error(json.error.message);
      }

      navigate('/NotVerified');
      

    } catch (error) {
      setError(error.message);
      console.log(error.message)
      const errorMessage = t(`errors.${error.message}`);
      console.log(errorMessage)
      
      notify("error", errorMessage);

    } finally {
      setIsLoading(false);
    }
  };

  return { signup, isLoading, error };
};
