import { Col, Row } from "react-bootstrap"

const Security = () => {


    return (
        <div className="accountTab">
            <div className="subTitle mt-4">
                <div className="texte">
                    Security
                </div>

            </div>
            <Row className="mt-3" style={{ maxWidth: "600px", margin: "auto" }}>
                <Col xs={4} md={4} style={{ textAlign: "right", lineHeight: "30px" }}>
                    Password :
                </Col>
                <Col>
                    <div className="my-btn-primary" style={{ width: "max-content" }}>Reset</div>
                </Col>
            </Row>
            <Row className="mt-3" style={{ maxWidth: "600px", margin: "auto" }}>
                <Col xs={4} md={4} style={{ textAlign: "right", lineHeight: "30px" }}>
                    Email :
                </Col>
                <Col>
                    <label style={{ display: "flex" }}>
                        <input
                            type="checkbox"
                            required
                        />
                        <div style={{ marginLeft: "10px" }}>Use my eamil to verified my identity</div>


                    </label>
                </Col>
            </Row>
            <Row className="mt-3" style={{ maxWidth: "600px", margin: "auto" }}>
                <Col xs={4} md={4} style={{ textAlign: "right", lineHeight: "30px" }}>
                    Cell Phone :
                </Col>
                <Col>
                    <label style={{ display: "flex" }}>
                        <input
                            type="checkbox"
                            required
                        />
                        <div style={{ marginLeft: "10px" }}>Use my cell phone to verified my identity</div>
                    </label>
                </Col>
            </Row>
        </div>
    )
}

export default Security