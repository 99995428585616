import { useEffect } from "react";
import { useGlobalContext } from "../hooks/useGlobalContext";
import { useNavigate } from "react-router-dom";
import Dock from "../components/system/dock"
import logo from "../imgs/interface/logo_big.png"
const Desktop = () => {
  const { user, userDispatch } = useGlobalContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user && !localStorage.getItem('user')) {
      navigate("/Signin");
    }
    const userLocal = JSON.parse(localStorage.getItem('user')); // Parse the stored string
    userDispatch({ type: 'LOGIN', payload: userLocal });
    
  }, [navigate]);

  return(
<div className="fullscreen">
  <div><img src={logo} style={{maxWidth:"60%"}} alt="" /></div>
  
</div>
  )
  
};

export default Desktop;
