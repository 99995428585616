import logo from '../../imgs/interface/logo_small.png'
import '../../css/signin.css'
import { useLogin } from '../../hooks/useLogin'
import { useState, useEffect } from 'react'

import { useNotify } from '../../hooks/useNotify';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SigninForm = () => {
    const { login, error, isLoading } = useLogin()
    const [userEmail, setUserEmail] = useState("")
    const [userPassword, setUserPassword] = useState("")
    const [alwaysConnected, setAlwaysConnected] = useState(false)

    const { notify } = useNotify()

    const [t, i18n] = useTranslation('global');

    const handleSubmit = async (e) => {
        e.preventDefault()
        await login(userEmail, userPassword, alwaysConnected)
    }

    useEffect(() => {
        if (error) {
            notify("error", error)
        }


    }, [error])

    return (

        <div className="subscribe">
            <form onSubmit={(function (e) {
                handleSubmit(e)
            }
            )}
            >


                <div className="logoBox">
                    <img className="logoSignin" src={logo} alt="" />
                </div>

                <h5 className="mb-3 fw-normal sousTitre">{t("signin.title")}</h5>

                <div className="form-floating">
                    <input type="email"
                        className="form-control"
                        style={{ borderRadius: "5px 5px 0px 0px" }}
                        onChange={(e) => { setUserEmail(e.target.value) }}
                        value={userEmail}
                        placeholder="name@example.com" required />
                    <label htmlFor="floatingInput">{t("formLabels.email")}</label>
                </div>
                <div className="form-floating">
                    <input type="password" className="form-control"
                        style={{ borderRadius: "0px 0px 5px 5px" }}
                        onChange={(e) => { setUserPassword(e.target.value) }}
                        value={userPassword}
                        placeholder="Password" required />
                    <label htmlFor="floatingPassword">{t("formLabels.password")}</label>
                </div>

                <div className="checkbox" style={{ marginTop: "20px", width: "100%", textAlign: "center", fontSize: "12px" }}>
                    <label>
                        <input
                            type="checkbox"
                            onChange={(e) => setAlwaysConnected(e.target.checked)}
                            checked={alwaysConnected}
                        />{" "}
                        {t("formLabels.always")}
                    </label>
                </div>


                <button type="submit" disabled={isLoading} className="my-btn-primary w-100 mt-3" id="btnConnectToAccount">
                    {t("signin.button")}
                </button>


                <div className="pasInscrit">
                    <div className="">{t("signin.account")} </div> 
                    <Link to="/Signup" className='noDecoration' >
                        <div className="btn-subscribe"
                            style={{ marginLeft: '10px' }}
                        >{t("header.btn-subscribe")}</div>
                    </Link>

                </div>
            </form>

        </div>
    )

}

export default SigninForm