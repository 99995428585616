
import logo from '../../imgs/interface/logo_big.png'
import { useTranslation } from 'react-i18next';

const VerifiedEmail = () => {

    const [t, i18n] = useTranslation('global');


    return (
        <div className="fullscreen">
            <div className="notVerified">
                <h3 className='text-center mt-3 mb-3'>{t("verified.welcome")}</h3>
                <div className='text-center'>
                    <img src={logo} className="logo" alt="" />
                </div>
                <h3 className='text-center mb-4 mt-4'>{t("verified.thanks")}</h3>
                <div  className='text-center'>{t("verified.message")}</div>
                <div  className='text-center'>{t("verified.preLaunch")}</div>
           
                
            </div>
        </div>
    )

}


export default VerifiedEmail